
@font-face {
	font-family: 'MonumentGrotesk-Black';
	src: url('fonts/MonumentGrotesk-Black.woff') format('woff'); /* IE9 Compat Modes */
}

@font-face {
	font-family: 'SelfModern';
	src: url('fonts/SelfModern.otf'); /* IE9 Compat Modes */
}

@font-face {
	font-family: 'FUDGrotesk-Trial-Light';
	src: url('fonts/FUDGrotesk-Trial-Light.otf'); /* IE9 Compat Modes */
}

html {
	scroll-behavior: smooth;
}

a,
a:visited,
a:hover,
a:hover,
a:active {
	text-decoration: none;
}

body {
	margin: 0;
	overflow-x: hidden;
	background-color: white;
}

header {
	top: 0;
}

footer {
	bottom: 0;
}

.homepage {
	background-color: white;
	height: 100vh;
}

.nav_container {
	position: fixed;
	display: flex;
	align-items: baseline;
	width: 100vw;
	z-index: 10;
	mix-blend-mode: difference;
	height: fit-content;
}


.index_image_component_caption h1:last-child{
	font-family: 'MonumentGrotesk-Black';
}

.nav_container section {
	width: 50%;
	padding: 1.5vw;
	padding-bottom: 1vw;
	padding-top: 1vw;
	display: flex;
	align-items: baseline;
}

.nav_container .nav_container_section_1 div {
	width: 50%;
}

.nav_container .nav_container_section_2 div:first-child {
	width: 70%;
}

.nav_container section h1 {
	margin: 0;
	color: #fff;
	font-weight: 100;
	font-size: 1.3vw;
	text-transform: uppercase;
	left: 1.5vw;
	cursor: pointer;
}

.toggle_transition_cta {
	position: fixed;
	writing-mode: vertical-rl;
	right: 0;
	z-index: 10;
	height: 100vh;
	font-size: 1vw;
	padding: 1.5vw;
	text-align: center;
	cursor: pointer;
	mix-blend-mode: difference;
}

.toggle_transition_cta h2 {
	font-family: 'SelfModern';
	text-transform: uppercase;
	margin: 0;
	font-weight: 100;
	color: white;
	font-size: 1.3vw;
}

header h1,
footer h1 {
	font-family: 'MonumentGrotesk-Black';
}

.index_video_component {
	display: flex;
	background-color: rgb(16, 16, 16);
	align-items: center;
	justify-content: center;
	height: 100vh;
	position: fixed;
	top: 0;
}

.index_video_component video {
	width: 100vw;
}

.index_video_component_main_container h1 {
	font-size: 2vw;
	font-family: times new roman;
	margin: 0;
	margin-left: 1.5vw;
	font-weight: 100;
	text-transform: uppercase;
}

.index_image_component_container {
	display: inline;
}

.index_image_component {
	z-index: 100;
	top: 4vw;
	left: 2vw;
	display: inline-block;
}

.index_image_component video {
	height: 15vw;
	margin: 1vw;
}

.project_template_details {
	position: fixed;
	bottom: 0;
	top: 2vw;
	left: 12vw;
	width: 88vw;
	height: 100%;
	overflow: scroll;
	z-index: 10;
}

.nav_project_template{
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nav_project_template div{
	width: 97%;
}

.project_template_details h1 {
	font-size: 1.2vw;
	margin: 0;
	color: white;
	font-family: 'SelfModern';
	font-weight: 100;
	text-transform: uppercase;
}

.project_template_details section div {
	padding-bottom: 2vw;
	margin-bottom: 2vw;
	display: block;
}

.project_template_details section div span {
	font-family: 'MonumentGrotesk-Black';
	display: block;
	color: white;
	font-size: 2.5vw;
}

.project_template_video_container {
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-top: 0;
	padding-top: 0;
	padding-bottom: 3vw;
	background-color:white;
}

iframe {
	display: block;
	background: #101010;
	border: none;
	height: calc(100vh - 150px);
	width: 90vw;
}

.ProjectTemplate header {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100vw;
}

.ProjectTemplate header div {
	width: 90vw;
	margin-top: 3vw;
	display: flex;
	align-items: center;
}

.ProjectTemplate header div h1 {
	color: rgb(16, 16, 16);
	font-weight: 100;
	font-size: 3vw;
	text-transform: uppercase;
	font-family: SelfModern;
	margin: 0;
}

.ProjectTemplate header div h1:first-child {
	width: 85%;
	font-family: MonumentGrotesk-rgb(16, 16, 16);
}

.ProjectTemplate header div h1:last-child {
	width: 15%;
}

.index_image_component h1 {
	color: rgb(16, 16, 16);
	font-weight: 100;
	font-size: 1.2vw;
	padding-bottom: 0.3vw;
	margin-left: 1vw;
	margin-right: 1.5vw;
	margin-top: 0.5vw;
	font-family: SelfModern;
	margin-bottom: 0;
}

.index_image_component h1:first-child {
	border-bottom: 1px solid rgb(16, 16, 16);
}

.index_image_component_main_container {
	z-index: 1;
	position: relative;
	top: 3vw;
	padding: 0.5vw;
}

.info {
	padding: 1.5vw;
	z-index: 100;
	width: 97vw;
	position: fixed;
	right: 0;
	height: 100vh;
	top: 0;
	box-shadow: -17px 1px 20px 5px rgb(16 16 16);
}

.info_background_layer{
	background-color: transparent;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 10;
}

.project_lightbox{
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: transparent;
	z-index: 1;
}

.lightbox_header,
.lightbox_footer{
	position: fixed;
	color: white;
	width: 50vw;
	left: 0;
	padding: 1vw;
	font-size: 1.3vw;
	text-align: center;
	font-family: 'SelfModern';
	display: flex;
	align-items: baseline;
}

.lightbox_header{
	top: 0;
	justify-content: flex-start;
}

.lightbox_footer{
	top: 0;
	right: 0;
	justify-content: flex-end;
}


.project_lightbox span{
	font-size: 9vw;
	color: rgb(16, 16, 16);
	z-index: 100;
	font-weight: 100;
	font-family: 'SelfModern';
	color: white;
	cursor: pointer;
	margin-right: 8vw;
	mix-blend-mode: difference;
}

.close_lightbox h1{
	font-weight: 100;
	cursor: pointer;
	text-align: left;
}

.first_arrow_lightbox{
	margin-right: 3vw;
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 1vw;
	padding-bottom: 0;
}

.second_arrow_lightbox{
	margin-left: 3vw;
}

.project_lightbox img{
	height: 100vh;
	width: auto !important;
}

.about_section {
	height: auto;
	padding: 1.5vw;
	top: 4vw;
	position: relative;
}

.about_section p {
	color: #f5f5f5;
	font-size: 1vw;
	font-family: arial rgb(16, 16, 16);
	margin: 0;
	padding-bottom: 2vw;
}

.about_section span {
	border-bottom: 1px solid #f5f5f5;
}


.main_info_text_container_project h1 {
	font-size: 5vw;
	font-weight: 100;
	margin: 0;
	font-family: arial rgb(16, 16, 16);
	text-transform: uppercase;
	color: grey;
}

.main_info_text_bio{
	margin: 0;
	padding-bottom: 1vw;
}

.main_info_text_container .main_info_text_bio div {
	display: inline-block;
}

.bio_highlighted_words_container {
	transform: rotate(45deg);
}

.main_info_text_container .main_info_text_bio h1 {
	color: #f5f5f5;
	font-size: 3vw;
	font-family: 'MonumentGrotesk-Black';
	margin: 0;
	padding-bottom: 2vw;
	margin-top: 1vw;
	font-weight: 100;
}

.main_info_text_container .main_info_text_bio .bio_words {
	color: #f5f5f5;
	font-size: 2.5vw;
	font-family: 'SelfModern';
	margin: 0;
	margin-bottom: 2vw;
	margin-top: 1vw;
	font-weight: 100;
}


.main_info_text_container .bio_highlighted_words span {
	color: white;
	font-size: 1.5vw;
	font-style: italic;
}

.main_info_text_container .main_info_text_secondary {
	display: flex;
	align-items: baseline;
}


.main_info_text_secondary {
	position: relative;
	width: 100%;
	margin-top: 3vw;
}

.main_info_text_secondary  section{
	width: 30%;
	margin-right: 4vw;
	padding-right: 4vw;
}


.main_info_text_secondary  section span{
	font-size: 1.3vw;
	margin: 0;
	color: white;
	font-family: 'SelfModern';
	font-weight: 100;
	text-transform: uppercase;
}


.main_info_text_secondary  section:first-child,
.main_info_text_secondary  section:nth-child(2){
	border-right: 1px solid white;
}

.main_info_text_secondary h1,
.main_info_text_secondary a{
	color: white;
	font-family: 'MonumentGrotesk-Black';
	margin: 0;
	font-size: 1.5vw;
}

.info_cta span{
	font-size: 1.3vw;
	margin: 0;
	font-family: 'MonumentGrotesk-Black';
	color: white;
	cursor: pointer;
	width: 50%;
}

.info_cta{
	margin-bottom: 2vw;
	display: flex;
	align-items: baseline;
}

.loading_page {
	height: 100vh;
	position: fixed;
	background-color: rgb(16, 16, 16);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
}

.loading_page_bubbles {
	width: fit-content;
	margin-left: 1vw;
	display: none;
}

.loading_page_bubbles .dot {
	display: inline-block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	margin-right: 3px;
	mix-blend-mode: difference;
	background: white;
	animation: wave 1.3s linear infinite;
}

.loading_page_bubbles .dot:nth-child(2) {
	animation-delay: -1.1s;
}

.loading_page_bubbles .dot:nth-child(3) {
	animation-delay: -0.9s;
}

.loading_page h1 {
	font-family: "FUDGrotesk-Trial-Light";
	color: white;
	font-weight: 100;
	margin: 0;
	mix-blend-mode: difference;
}

.info_slider_captions {
	display: flex;
	align-items: baseline;
	width: 100%;
}

.info_slider_main_container {
	display: flex;
	align-items: baseline;
}

.info_slider_main_container section {
	padding: 2vw;
	padding-top: 0;
}

.info_slider_captions div:first-child {
	width: 80%;
}

.info_slider_captions div:last-child {
	width: 20%;
}

.info_slider_captions h1 {
	color: #f5f5f5;
	font-weight: 100;
	font-size: 1vw;
	font-family: SelfModern;
	cursor: pointer;
}

.info_slider_main_container section img {
	height: 34vh;
}

.splashpage_main_container {
	z-index: 100;
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.transition_section {
	top: 0;
	height: 100vh;
	width: 100vw;
	left: 0;
	position: fixed;
	background-color: rgb(16, 16, 16);
}

footer{
	float: right;	
	writing-mode: vertical-rl;
	display: flex;
	z-index: 1000;
}

footer h1{
	padding: 1.5vw;
	padding-bottom: 3vw;
	margin: 0;
    color: black;
    font-weight: 100;
    font-size: 1.3vw;
    text-transform: uppercase;
    cursor: pointer;
}



.transition_section nav {
	position: fixed;
  display: block;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  mix-blend-mode: difference;
  align-items: center;
  justify-content: center;
}

.splashpage_main_container nav {
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	mix-blend-mode: difference;
	align-items: baseline;
	flex-direction: column;
}

.splashpage_main_container h1 {
	color: white;
	width: 100vw;
	height: 100vh;
	line-height: 1;
	font-family: 'FUDGrotesk-Trial-Light';
	mix-blend-mode: difference;
	margin: 0;
	font-weight: 100;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: -0.2px;
}

.splashpage_main_container h1,
.loading_page h1{
	font-size: 3vw;
}

.splashpage_main_container h1:last-child {
	bottom: 0;
	position: absolute;
	text-align: right;
	text-transform: lowercase;
	display: none;
}

.splashpage_main_container h1:first-child {
	text-transform: capitalize;
	line-height: 0.5;
}

.transition_section h1 {
	color: white;
	font-size: 8vw;
	padding: 1.5vw;
	line-height: 1;
	width: 97%;
	font-family: 'MonumentGrotesk-Black';
	mix-blend-mode: difference;
	margin: 0;
	font-weight: 100;
	text-align: center;
}

.transition_section h1:first-child {
	top: 0;
	position: absolute;
}
.transition_section h1:last-child {
	bottom: 0;
	position: absolute;
	text-transform: uppercase;
}

.credits{
    position: fixed;
    bottom: 0;
    color: white;
    margin-bottom: 1vw;
    font-family: 'SelfModern';
}


.footer_container{
	height: 100vh;
    display: flex;
    align-items: center;
    right: 0;
    position: fixed;
    z-index: 10000;
}

.transition_video{
	width: 100vw;
}

.archives_main_container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	margin-top: 7vw;
	position: absolute;
}

.archives_cell_container {
	width: 50%;
	padding-top: 1vw;
	padding-bottom: 1vw;
	display: inline-block;
}

.archives_cell_container div {
	width: 50%;
	padding: 1.5vw;
	padding-bottom: 0;
	padding-top: 0;
}

.archives_cell_container div span:first-child {
	text-transform: uppercase;
}

.archives_cell_container div span {
	display: block;
	cursor: pointer;
	font-size: 1vw;
	font-family: SelfModern;
	color: #f5f5f5;
}

.selected_project_caption {
	position: fixed;
	left: 0;
	bottom: 3vw;
	color: white;
	z-index: 10000;
	mix-blend-mode: difference;
}

.selected_project_caption h1 {
	font-family: 'MonumentGrotesk-Black';
	font-size: 5.5vw;
	text-transform: uppercase;
	font-weight: 100;
	line-height: 1;
}



.selected_projects_text_cell_container {
    position: relative;
    padding: 1.5vw;	
    display: block;
    width: 97%;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border-bottom: 1px solid;
    margin-bottom: 4vw;
    background-color: black;
    color: white;
}

.selected_projects_text_cell {
	padding-left: 0;
	width: auto;
	display: inherit;
	margin-right: 2vw;
	margin-bottom: 0;
	display: inline-block;
}

.selected_projects_text_cell:last-child{
	border-right: 0;
}

.selected_projects_text_cell h1 {
	mix-blend-mode: difference;
	margin: 0;
	font-weight: 100;
	font-size: 1.3vw;
	left: 1.5vw;
	cursor: pointer;
	font-family: 'MonumentGrotesk-Black';
	text-transform: uppercase;
	padding-bottom: 1vw;
	padding-top: 1vw;
	border-bottom: 1px solid white;
}

.selected_projects_text_cell h1:last-child{
	border-bottom: none;
}

.selected_projects_text_cell h1 span{
	font-family: SelfModern;
}

.template_video_info_cta {
	position: absolute;
	flex-direction: column;
	display: flex;
	align-items: baseline;
	left: 0.75vw;
	padding-left: 0.75vw;
	z-index: 100;
	width: -moz-fit-content;
	width: fit-content;
	top: 4.3vw;
}

.template_video_info_cta h1 {
	color: rgb(16, 16, 16);
	font-size: 3.5vw;
	margin: 0;
	font-family: 'SelfModern';
	line-height: 1;
  font-weight: 100;
	margin: 2vw;
	margin-left: 0;
	margin-top: 0;
}

.selected_projects_media_cell_container {
	width: 100vw;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	background-color: white;
}

.selected_projects_media_cell nav{
	width: 97%;
}


.selected_projects_media_cell_headline h1{
	font-size: 6vw;
	margin: 0;
	font-family: 'MonumentGrotesk-Black';
	color: rgb(16, 16, 16);
	line-height: 1;
	margin-top: 2vw;
}

.selected_projects_media_cell_headline span{
	font-family: "SelfModern";
	font-size: 6vw;
	margin: 0;
	color: rgb(16, 16, 16);
	line-height: 1;
	margin-top: 2vw;
	display: none;
}

.selected_projects_media_cell {
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 8vw;
}

.selected_projects_img_container {
	width: 100%;
}


.img_container_labels span{
	width: auto;
	display: block;
	color: rgb(16, 16, 16);
	font-weight: 100;
	font-size: 1.2vw;
	padding-bottom: 0.3vw;
	margin-top: 0.5vw;
	font-family: "SelfModern";
	margin-bottom: 0;
}

.img_container_labels span:first-child{
	font-family: 'MonumentGrotesk-Black';
}

.selected_projects_media_cell .img_container {
	display: block;
	align-items: center;
	justify-content: center;
	padding-top: 1vw;
	padding-bottom: 1vw;
}

.selected_projects_media_cell .img_container img {
  width: 55%;
}

.selected_projects_media_cell_container video,
.selected_projects_media_cell_container img {
	width: 100%;
	display: block;
	padding: 0;
}

.selected_projects_media_cell_container .vertical {
	width: auto;
	height: 100vh;
	position: absolute;
	top: 0;
}

.seleted_projects_information_container {
	position: fixed;
	bottom: 0;
	padding: 1.5vw;
	width: 97%;
	display: flex;
	flex-direction: row-reverse;
	align-items: baseline;
	mix-blend-mode: difference;
}


.seleted_projects_information_container div {
	width: 50%;
}

.seleted_projects_information_container div h1 {
	font-family: 'MonumentGrotesk-Black';
	font-size: 1vw;
	color: #f5f5f5;
	margin: 0;
	font-weight: 100;
	text-transform: uppercase;
}

.selected_projects_caption_container {
	position: absolute;
	top: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.selected_projects_text_container {
	height: 100vh;
	width: 100vw;
	background-color: transparent;
	bottom: 0;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.blinking_span {
	animation: blink-animation 3s steps(5, start) infinite;
	color: white;
}

@keyframes wave {
	0%,
	60%,
	100% {
		transform: none;
	}
	30% {
		transform: translateY(-5px);
	}
}

@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}

@media only screen and (max-width: 600px) {


	.img_container{
		padding-left: 0 !important;
	}


		body{
			overflow-x: hidden;
		}

		.info{
			height: auto;
			overflow: scroll;
			height: 100vh;
		}

		.project_template_video_container{
			height: 100vh;
		}

		.template_video_info_cta{
			flex-direction: column;
		}


		header {
	    top: 50vw;
		}

		footer{
			top: 0;
		}

	footer h1{
		font-family: 'MonumentGrotesk-Black';
	}

	header h1{
		font-family: SelfModern;
	}


	.project_template_details {
		top: 6.5vw;
	}

	.loading_page_bubbles .dot {
		width: 4px;
		height: 4px;
	}

	.index_image_component_main_container {
		z-index: 1;
		position: relative;
		top: 80vw;
		padding: 0.5vw;
	}


	.seleted_projects_information_container div:last-child,
	.seleted_projects_information_container div:first-child {
		width: 100%;
	}

	.seleted_projects_information_container div h1 {
		font-size: 4.5vw;
	}

	.info_cta span{
		font-size: 7vw;
	}

	.splashpage_main_container video{
		display: none;
	}

	.main_info_text_container .main_info_text_bio .bio_words,
	.main_info_text_container .bio_highlighted_words span {
		font-size: 4vw;
	}

	.main_info_text_container .main_info_text_secondary{
		flex-direction: column;
	}
	.main_info_text_secondary section{
		width: 100%;
	}

	.main_info_text_secondary section span{
		font-size: 5vw;
		margin-bottom: 3vw;
		font-family: 'MonumentGrotesk-Black';
	}

	.main_info_text_secondary section:first-child{
		border: none;
	}

	.main_info_text_secondary section{
		margin-bottom: 1vh;
	}

	.main_info_text_container .bio_highlighted_words {
		transform: rotate(3deg);
	}

	.homepage {
		background-color: rgb(16, 16, 16);
		height: auto;
	}

	.index_image_component h1 {
		font-size: 4.7vw;
		font-family: 'SelfModern';
	}

	.nav_container {
		flex-direction: column;
		position: absolute;
	}

	.nav_container section h1 {
		font-size: 4.5vw;
	}

	.selected_projects_text_cell h1 {
		font-size: 4.5vw;
	}

	.selected_projects_media_cell .img_container img {
	  width: 96%;
		cursor: pointer;
	}

	.seleted_projects_information_container {
		flex-direction: column;
	}

	.nav_container section {
		width: 97%;
		flex-direction: column;
	}

	.index_image_component {
		display: block;
	}

	.index_video_component {
		display: none;
	}

	.index_image_component_caption {
		width: 98%;
	}

	.index_image_component video {
		height: auto;
		width: 98%;
	}

	.index_image_component img {
		height: auto;
		width: 98%;
		margin: 1vw;
	}

	.index_image_component h1:first-child {
		border-bottom: 1px solid black;
	}

	.selected_projects_media_cell .img_container {
		width: 90vw;
		display: block;
		padding: 2vw;
	}

	.template_video_info_cta h1 {
		font-size: 9vw;
	}

	.project_template_details h1 {
		font-size: 4.5vw;
	}

	.project_template_details section div span {
		font-size: 4.5vw;
	}

	.toggle_transition_cta {
		display: none;
	}

	.nav_container .nav_container_section_2 div:first-child,
	.nav_container .nav_container_section_1 div {
		width: fit-content;
	}

	.nav_container section {
		padding-bottom: 0;
		padding-top: 0;
	}

	.index_image_component h1 {
		margin: 0;
	}

	.main_info_text_container .bio_highlighted_words span {
		color: white;
	}

	.main_info_text_container .main_info_text_secondary div h1,
	.main_info_text_container .main_info_text_secondary div h1 a {
		color: white;
		font-size: 4vw;
		font-family: 'SelfModern';
	}

	.main_info_text_container{
		height: 100%;
		overflow: scroll;
	}

	.index_image_component_caption {
		margin-top: 4vw;
		margin-bottom: 4vw;
	}

	.loading_page {
		padding: 0;
	}

	.loading_page_bubbles {
		margin-left: 5vw;
	}

	.loading_page h1 {
		font-family: 'SelfModern';
	}

	.selected_projects_text_cell {
		width: 100%;
	}

	.selected_projects_text_cell {
		width: 100%;
		margin-right: 1.5vw;
		margin: 0;
		padding: 0;
		padding-bottom: 1vw;
	}

	iframe {
		height: fit-content;
		display: block;
	}

	.selected_projects_media_cell_container nav h1 {
		margin-bottom: 2vw;
	}

	.selected_projects_media_cell_container{
		padding-bottom: 21vw;
	}

	.splashpage_main_container{
		height: 80vh;
	}

	.selected_projects_media_cell .img_container{
		height: auto;
	}

	.selected_projects_media_cell .img_container img,
	.img_container_labels{
		padding-left: 2vw;
	}


	.img_container_labels span{
		font-size: 4vw;
	}

	.splashpage_main_container h1,
	.loading_page h1{
		font-size: 10vw;
		color: black;
		height: 60vh;
	}
}


.__reset {
	background:none;
	border:none;
}
